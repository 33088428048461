//import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
//import Form from 'react-bootstrap/Form';
//import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
//import Image from 'react-bootstrap/Image';
//import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../images/logo.png';
//import { useUserAuth } from "../context/UserAuthContext";

function MainNavbar() {
  /*const { handleLogout, user } = useUserAuth();
  const photoUrl = user != null ? user.photoURL : ''
  const displayName = user != null ? user.displayName : ''
  const loginHiddenClass = user != null ? 'visually-hidden' : ''
  const logoutHiddenClass = user != null ? '' : 'visually-hidden'*/
  return (
    <>
      {['lg'].map((expand) => (
        <Navbar bg="white" data-bs-theme="white" key={expand} expand={expand}>
          <Container fluid>
            <Navbar.Brand href="/"><img className="app-logo" src={logo} alt="BloomTap"/></Navbar.Brand>
            {/* Menu
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
				 <Image src={photoUrl} className="pr-5" width="30px" height="30px" roundedCircle/>
                  {displayName}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
				  {user != null ? <Nav.Link href="/bookmark">My Bookmarks</Nav.Link> : ''}
				  <Nav.Link href="/login" className={loginHiddenClass}>Login</Nav.Link>
				  <button onClick={handleLogout} className={logoutHiddenClass}>Logout</button>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            */}
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default MainNavbar;
