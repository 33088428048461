import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

function ShopProfilesHeader(props) {
    const { counter, profile, bloomName, isHidden } = props
 
    return (
		<div id={"profile-header" + counter} className={"profile-header-container " + (isHidden ? 'visually-hidden' : '')}>
			<div className="profile-image-container">
				<img className="profile-image" src={profile.photoUrl} alt="BloomTap Profile Image"/>
				<h2>{profile.displayName}</h2>
				<div className="bloom-bloomname"><FontAwesomeIcon icon={faLink}  />  {bloomName}</div>
			</div>
		</div>
    )
}

export default ShopProfilesHeader
